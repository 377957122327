
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'hotIssueList',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      data.getList()
    })

    const data = reactive({
      encryptData: store.state.app.encryptData,
      selectedItem: 1,
      query: {
        page: 1,
        paginate: 20
      },
      list: null,
      total: 0,

      async getList () {
        const { res } = await indexApi.problemList(data.query)
        console.log('获取列表', res.data)

        data.list = res.data || []
        data.total = res.total
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
      },
      // 详情跳转
      onDetails (id: number) {
        router.push(`/hotIssue/details?dataItem=${data.encryptData}&id=${id}`)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
